import { defineStore } from "pinia";
import orderBy from "lodash/orderBy";
import PROFILE_BY_SEONAME from "~/graphql/profileBySeoName.graphql";

export const useProfessionalStore = defineStore("professional", {
  state: () => ({
    person: null,
    personLoading: false,
    personLoaded: false,
    city: null,
    profession: null,
    professionTask: null,
    hasPicture: 1,
  }),
  getters: {
    prices: (state) => {
      return state.person?.prices || [];
    },
    rankedProfessions: (state) => {
      const orderedProfessions = orderBy(
        state.person?.professions || [],
        ["winCount"],
        ["desc"]
      );
      if (state.profession) {
        const index = orderedProfessions.findIndex(
          (x) => x.id === state.profession.id
        );
        if (index !== -1) {
          orderedProfessions.splice(index, 1);
          orderedProfessions.unshift(state.profession);
        }
      }

      return orderedProfessions;
    },
    professionIds: (state) => {
      return (state.person?.professions || []).map((p) => p.id);
    },
    activeProfessionScores(state) {
      return (state.person?.scores || []).filter(
        (score) =>
          this.professionIds.includes(score.profession?.id) || !score.profession
      );
    },
    inactiveProfessionScores(state) {
      return (state.person?.scores || []).filter(
        (score) =>
          !this.professionIds.includes(score.profession?.id) && score.profession
      );
    },
  },
  actions: {
    async loadPerson(seoName) {
      if (this.personLoaded && seoName === this.person?.seoName) {
        // don't reload if already loaded
        return;
      }
      try {
        this.personLoading = true;

        const professionSeoName = this.$nuxt.route.query.profession;
        const citySeoName = this.$nuxt.route.query.city;
        const professionTaskSeoName = this.$nuxt.route.query.professionTask;

        const resp = await this.$nuxt.$query(
          PROFILE_BY_SEONAME,
          {
            seoName,
            citySeoName,
            professionSeoName,
            professionTaskSeoName,
          },
          { showErrorPage: true }
        );

        this.person = resp.personBySeoName;
        this.city = resp.cityBySeoName;
        this.profession = resp.professionBySeoName;
        this.professionTask = resp.professionTaskBySeoName;
        if (this.professionTask) {
          const professionIds = this.professionTask.professions.map(
            (p) => p.id
          );
          const matchingProfession = (this.person?.professions ?? []).find(
            (p) => professionIds.includes(p.id)
          );
          if (matchingProfession) {
            this.profession = matchingProfession;
          }
        }

        this.personLoaded = true;
      } catch {
      } finally {
        this.personLoading = false;
      }
    },
    async reportPerson(message) {
      try {
        const REPORT_PERSON = await import("@/graphql/reportPerson.graphql");
        return await this.$nuxt.$mutate(REPORT_PERSON, {
          message,
        });
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            professional: "report_person",
          },
        });
        return Promise.reject(err);
      }
    },
    setScoreDeleted({ id, value }) {
      const score = this.person.scores.find((x) => x.id === id);
      score.deleted = value;
    },
    async reloadPerson() {
      if (!this.person) {
        throw new Error("No person to reload");
      }
      this.personLoaded = false;
      await this.loadPerson(this.person.seoName);
    },
    pictureError() {
      this.hasPicture = 0;
    },
  },
});
